<template>
  <IAmOverlay :loading="loading || loadingUpload">
    <section>
      <b-card-title
        class="py-1 m-0"
        style="position: sticky; top: 45px; z-index: 100; background: white;"
      >
        <div class="d-flex-between gap-1">
          <div class="d-flex">
            <div v-if="readonly">
              <feather-icon
                v-if="!isMobileView"
                icon="UserIcon"
                size="24"
                class="mx-50"
              />
              <span :class="isMobileView ? 'font-medium-2 text-nowrap' : ''">
                {{ $t('agency.agencyDetail') }}
              </span>
            </div>
            <div v-else>
              <feather-icon
                v-if="!isMobileView"
                icon="EditIcon"
                size="24"
                class="mx-50"
              />
              <span :class="isMobileView ? 'font-medium-2' : ''">
                {{ $t('agency.editAgency') }}
              </span>
            </div>
            :<kbd class="text-dark ml-50">{{ agencyToEdit?.agencyCode }}</kbd>
          </div>
          <!-- Right Button Edit -->
          <div>
            <template v-if="readonly">
              <div :class="isMobileView ? 'd-flex flex-wrap justify-content-end' : ''">
                <!-- ANCHOR reset balance button -->
                <b-button
                  v-if="isVisibleResetBalance"
                  v-b-modal.modal-edit-balance
                  variant="danger"
                  class="py-50 py-md-1 mt-50 mt-md-0 mr-1"
                >
                  <span class="">{{ $t('agency.editBalance.title') }}</span>
                </b-button>

                <b-button
                  :variant="allowTopup ? 'warning' : ''"
                  :disabled="!allowTopup || !(canAccess('topup.requestTopup') || (['OPE', 'SE'].includes(meData?.type) && isRoleF1))"
                  class="py-50 py-md-1 mt-50 mt-md-0"
                  :class="{ 'cursor-not-allowed': !allowTopup }"
                  @click="handleShowModalCreateTopup"
                >
                  <span class="">{{ isMobileView ? 'Tạo lệnh nạp tiền' : $t('agency.topupForAgency') }}</span>
                </b-button>
                <b-button
                  v-if="allowEdit"
                  variant="info"
                  :disabled="!canAccess('agency.updateAgency')"
                  class="ml-1 py-50 py-md-1"
                  :class="isMobileView ? 'mt-50' : ''"
                  @click="handleEdit"
                >
                  <span class="">{{ $t('edit') }}</span>
                </b-button>
              </div>
            </template>
            <b-button
              v-else
              variant="outline-info"
              :disabled="!canAccess('agency.updateAgency')"
              class="ml-1"
              :class="isMobileView ? 'p-1' : ''"
              @click="handleCancelEdit"
            >
              <span class="">{{ $t('cancelEdit') }}</span>
            </b-button>
          </div>
        </div>
      </b-card-title>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="position-relative"
          @submit.prevent="handleSubmit(updatedAgencyHandle)"
        >
          <!-- SECTION Card agency basic info -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border"
          >
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.agencyInfo') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR Agency Owner Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelOwner')"
                    rules="required|max:150"
                  >
                    <b-form-group label-for="agencyOwner">
                      <template #label>
                        {{ $t('agency.labelOwner') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <b-form-input
                        id="agencyOwner"
                        v-model="agencyToEdit.agencyOwner.name"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="150"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderOwner')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Email Address: email, max 150 -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelEmail')"
                    :rules="`required|email|max:150`"
                    mode="eager"
                  >
                    <!-- Bỏ unique: |isUnique:agencies,email,${agenciesData.emailAddress} -->
                    <b-form-group label-for="emailAddress">
                      <template #label>
                        {{ $t('agency.labelEmail') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <b-form-input
                        id="emailAddress"
                        v-model="agencyToEdit.emailAddress"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        debounce="300"
                        :placeholder="$t('agency.placeholderEmail')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Agency Owner Phone Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelOwnerPhone')"
                    rules="required|phoneNumber"
                  >
                    <b-form-group label-for="ownerPhone">
                      <template #label>
                        {{ $t('agency.labelOwnerPhone') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <b-form-input
                        id="ownerPhone"
                        v-model="agencyToEdit.agencyOwner.phoneNumber"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderOwnerPhone')"
                      />
                      <!-- maxlength="10" -->

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Date Of Birth -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.birthday')"
                  >
                    <b-form-group label-for="birthday">
                      <template #label>
                        {{ $t('agency.birthday') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-datepicker
                        v-model="agencyToEdit.birthDayOwner"
                        name="birthday"
                        class="form-control"
                        :placeholder="$t('customer.placeholderDoB')"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :min="new Date(1920, 0, 1)"
                        :max="new Date()"
                        show-decade-nav
                        hide-header
                        locale="vi"
                        no-flip
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Identity Card Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelIdentityCardNum')"
                    rules="max:15"
                  >
                    <b-form-group label-for="identityCardNumber">
                      <template #label>
                        {{ $t('agency.labelIdentityCardNum') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="identityCardNumber"
                        v-model="agencyToEdit.identityCardNumber"
                        v-remove-non-numeric-chars.allNumber
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderIdentityCardNum')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Identity Card Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelIdentityCardName')"
                    rules=""
                  >
                    <b-form-group label-for="identityCardName">
                      <template #label>
                        {{ $t('agency.labelIdentityCardName') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="identityCardName"
                        v-model="agencyToEdit.identityCardName"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        maxlength="255"
                        :placeholder="$t('agency.placeholderIdentityCardName')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Place Of Issue -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelPlaceOfIssue')"
                    rules=""
                  >
                    <b-form-group label-for="placeOfIssue">
                      <template #label>
                        {{ $t('agency.labelPlaceOfIssue') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="placeOfIssue"
                        v-model="agencyToEdit.placeOfIssue"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        maxlength="255"
                        :placeholder="$t('agency.placeholderPlaceOfIssue')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <!-- ANCHOR Identity card front image -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        ref="refProviderIDFrontImageFile"
                        :name="$t('agency.uploadFrontId')"
                      >
                        <b-form-group
                          class="font-weight-bold"
                          label-for="frontIdFrontImage"
                        >
                          <template #label>
                            <div>{{ $t('agency.uploadFrontId') }}</div>
                          </template>
                          <div
                            v-if="!readonly"
                            class="input-group custom-file-button"
                          >
                            <label
                              class="input-group-text"
                              for="frontIdFrontImage"
                            >
                              {{ $t('agency.uploadImage') }}</label>
                            <b-form-file
                              id="frontIdFrontImage"
                              ref="refInputFrontIDPhoto"
                              v-model="agencyFile.idFrontImage"
                              :disabled="readonly"
                              accept=".jpg, .png"
                              class="form-control"
                              plain
                              placeholder="Chưa chọn ảnh"
                              :state="getValidationState(validationContext) === false ? false : null"
                              @input="inputImageIDCardFrontFormData"
                            />
                          </div>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ANCHOR Identity card back image -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        ref="refProviderIDBackImageFile"
                        :name="$t('agency.uploadBackId')"
                      >
                        <b-form-group
                          class="font-weight-bold"
                          label-for="frontIdBackImage"
                        >
                          <template #label>
                            {{ $t('agency.uploadBackId') }}
                          </template>
                          <div
                            v-if="!readonly"
                            class="input-group custom-file-button"
                          >
                            <label
                              class="input-group-text"
                              for="frontIdBackImage"
                            >
                              {{ $t('agency.uploadImage') }}</label>
                            <b-form-file
                              id="frontIdBackImage"
                              ref="refInputBackIDPhoto"
                              v-model="agencyFile.idBackImage"
                              accept=".jpg, .png"
                              :disabled="readonly"
                              class="form-control"
                              plain
                              placeholder="Chưa chọn ảnh"
                              :state="getValidationState(validationContext) === false ? false : null"
                              @input="inputImageIDCardBackFormData"
                            />
                          </div>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>

                <!-- ANCHOR ID front image -->
                <b-col
                  cols="5"
                  md="6"
                  centered
                >
                  <b-img
                    v-if="agencyToEdit.identityCardFrontImage"
                    :src="agencyToEdit.identityCardFrontImage"
                    fluid
                    alt="id card"
                    style="max-height: 200px"
                    class="border shadow-lg mb-1"
                  />
                  <span
                    v-else
                    class="text-secondary"
                  >
                    Chưa có ảnh
                  </span>
                </b-col>

                <!-- ANCHOR Id back image -->
                <b-col
                  cols="5"
                  md="6"
                  centered
                >
                  <b-img
                    v-if="agencyToEdit.identityCardBackImage"
                    :src="agencyToEdit.identityCardBackImage"
                    fluid
                    alt="id card"
                    style="max-height: 200px"
                    class="border shadow-lg mb-1"
                  />
                  <span
                    v-else
                    class="text-secondary"
                  >
                    Chưa có ảnh
                  </span>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Agency business information -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
          >
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.agencyBusinessInfo') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR company name -->
                <b-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyCompanyName')"
                    rules="required"
                  >
                    <b-form-group label-for="agency-name">
                      <template #label>
                        {{ $t('agency.agencyCompanyName') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <b-form-input
                        id="agency-name"
                        v-model="agencyToEdit.agencyName"
                        :disabled="readonly"
                        :name="$t('agency.agencyCompanyName')"
                        :placeholder="`${$t('agency.enter')} ${$t(
                          'agency.agencyCompanyName',
                        )}`"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Tax Code -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Tax Code"
                    rules=""
                  >
                    <b-form-group label-for="tax-code">
                      <template #label>
                        {{ $t('agency.labelTaxCode') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="tax-code"
                        v-model="agencyToEdit.taxCode"
                        v-remove-non-numeric-chars.allNumber
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderTaxCode')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Send Monthly Report -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelMonthlyReport')"
                    rules=""
                  >
                    <b-form-group
                      label-for="enableSendMonthlyReport"
                      :state="getValidationState(validationContext) === false
                        ? false
                        : null
                      "
                    >
                      <template #label>
                        {{ $t('agency.labelMonthlyReport') }}
                      </template>
                      <v-select
                        v-if="!readonly"
                        v-model="agencyToEdit.enableSendMonthlyReport"
                        :options="[
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ]"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="enableSendMonthlyReport"
                        :placeholder="$t('agency.placeholderMonthlyReport')"
                      >
                        <template #option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-input
                        v-else
                        :value="$t([
                          { label: 'Yes', value: true },
                          { label: 'No', value: false },
                        ].find(item => item.value === agencyToEdit.enableSendMonthlyReport).label)"
                        disabled
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Agency Code -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyCode')"
                    rules=""
                  >
                    <!-- :rules="`min:${isRoleF1 ? '2' : '4'}|userName|isUnique:agencies,agencyCode`" -->
                    <b-form-group
                      :label="$t('agency.agencyCode')"
                      label-for="agencyCode"
                    >
                      <b-form-input
                        id="agencyCode"
                        :value="agencyToEdit.agencyCode.toUpperCase()"
                        :disabled="readonly || true"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="upperCaseFormatter"
                        :placeholder="$t('agency.agencyCode')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR company address -->
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label-for="agency-address"
                    :label="$t('myAccount.addressCompany')"
                  >
                    <b-form-input
                      id="agency-address"
                      v-model="agencyToEdit.agencyAddress"
                      :disabled="readonly"
                      :name="$t('myAccount.addressCompany')"
                      :placeholder="`${$t('myAccount.enter')} ${$t(
                        'myAccount.addressCompany',
                      )}`"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Founding Date -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyFoundingDate')"
                  >
                    <b-form-group
                      :label="`${$t('agency.agencyFoundingDate')}`"
                      label-for="agencyFoundingDate"
                    >
                      <b-form-datepicker
                        v-model="agencyToEdit.agencyFoundingDate"
                        :name="$t('agency.agencyFoundingDate')"
                        class="form-control"
                        :disabled="readonly"
                        :placeholder="$t('agency.agencyFoundingDate')"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"
                        :min="new Date(1920, 0, 1)"
                        :max="new Date()"
                        show-decade-nav
                        hide-header
                        locale="vi"
                        no-flip
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Payment method -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.paymentMethod')"
                    rules="required"
                  >
                    <b-form-group
                      class="font-weight-bold"
                      label-for="paymentMethod"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('agency.paymentMethod') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <v-select
                        v-if="!readonly"
                        v-model="agencyToEdit.paymentMethod"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="[
                          { label: 'overdraft', value: 'OVERDRAFT' },
                          { label: 'prepay', value: 'PREPAY' },
                        ]"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="paymentMethod"
                        :placeholder="$t('agency.paymentMethod')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(`agency.${data.label}`) }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $t(`agency.${data.label}`) }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-input
                        v-else
                        :value="$t(
                          `agency.${[
                            { label: 'overdraft', value: 'OVERDRAFT' },
                            { label: 'prepay', value: 'PREPAY' },
                          ].find(
                            (item) =>
                              item.value === agencyToEdit.paymentMethod,
                          ).label
                          }`,
                        )
                        "
                        disabled
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Document contract -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    ref="documentContractFile"
                    :name="`${$t('agency.documentContract')}`"
                  >
                    <b-form-group class="font-weight-bold">
                      <template #label>
                        <div>
                          {{ $t('agency.documentContract') }}
                        </div>
                      </template>
                      <div>
                        <div v-if="agencyToEdit.documentContractUrl.length">
                          <div
                            v-for="(docContractFile, docContractFileIndex) in agencyToEdit.documentContractUrl"
                            :key="docContractFileIndex"
                          >
                            <em>{{ docContractFileIndex + 1 }}.</em>
                            <a
                              :href="docContractFile"
                              alt="download file"
                              target="_blank"
                              class="hover-underline"
                            >
                              {{ shortenFileName(docContractFile) }}
                            </a>
                            <b-button
                              v-if="!readonly"
                              variant="flat-danger"
                              class="p-50 rounded-circle"
                              @click="deleteDocumentContract(docContractFileIndex)"
                            >
                              <IAmIcon icon="closeOutline" />
                            </b-button>
                          </div>
                        </div>
                        <span
                          v-else
                          class="text-secondary"
                        >
                          Chưa có file
                        </span>
                        <div v-if="!readonly">
                          <!-- <label
                            for="documentContractUrlFile"
                            class="bg-info p-50 rounded text-white cursor-pointer hover-label"
                          >
                            <div class="d-flex-center gap-1">
                              <IAmIcon
                                icon="cloudUploadOutline"
                                size="18"
                                color="white"
                              />
                              {{ $t('agency.uploadContract') }}
                            </div>
                          </label> -->
                          <div class="input-group custom-file-button">
                            <label
                              class="input-group-text"
                              for="documentContractUrlFile"
                            >
                              {{ $t('agency.uploadContract') }}</label>
                            <b-form-file
                              id="documentContractUrlFile"
                              ref="refInputDocumentContractUrl"
                              v-model="agencyFile.documentContract"
                              :accept="fileCanUpload"
                              :disabled="readonly"
                              class="form-control"
                              plain
                              multiple
                              :state="getValidationState(validationContext) === false ? false : null"
                              @input="handleUploadContractFiles"
                            />
                          </div>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <small class="font-italic text-secondary">Hệ thống chỉ hỗ trợ tải file {{ fileCanUpload }} có dung lượng nhỏ hơn {{ sizeFormatter(MAX_SIZE_TO_UPLOAD_FILE) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR id booker  -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.bookerId')"
                    rules="bookerId|min:5"
                  >
                    <b-form-group
                      :label="$t('agency.bookerId')"
                      label-for="bookerId"
                    >
                      <b-form-input
                        id="bookerId"
                        v-model="agencyToEdit.bookerId"
                        :disabled="readonly"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :formatter="trimAllInputAndUpper"
                        :placeholder="$t('agency.bookerId')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR limitCredit  -->
                <b-col
                  v-if="agencyToEdit.id !== 1000000"
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    class="font-weight-bold"
                    label-for="agency-limit-credit"
                    :label="`${$t('agency.labelLimitCredit')}`"
                  >
                    <div class="d-flex align-items-center gap-2">
                      <IAmInputMoney
                        :value-money.sync="agencyToEdit.limitCredit"
                        class="fit-content text-dark font-medium-1 font-weight-bolder"
                        disabled
                      />
                      <b-button
                        v-if="!(readonly && !['KTT', 'KTV'].includes(meData?.type))"
                        v-ripple.400="'rgba(0, 207, 232, 0.15)'"
                        variant="outline-primary"
                        class="px-1 py-50 rounded-lg text-nowrap"
                        @click="openChangeBalanceModal(agencyToEdit)"
                      >
                        {{ $t('adjust') }}
                      </b-button>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION STATUS -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border my-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.labelStatus') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR Status -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelStatus')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="status"
                      :state="getValidationState(validationContext) === false
                        ? false
                        : null
                      "
                    >
                      <template #label>
                        {{ $t('agency.labelStatus') }}
                        <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span>
                      </template>
                      <v-select
                        v-model="agencyToEdit.status"
                        :options="statusAgencyOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        :disabled="readonly"
                        input-id="status"
                        :placeholder="$t('agency.placeholderStatus')"
                      >
                        <template #option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border my-1"
          >
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.airAccountForAgencyText') }}
              </h6>
            </template>
            <!-- Form Input -->
            <b-card-body class="p-0">
              <h5 class="my-2">
                {{ $t('agency.provideAccount') }}
              </h5>
              <b-row class="mt-1">
                <!-- User name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelAirUsername')"
                    rules=""
                  >
                    <b-form-group label-for="airAccountUsername">
                      <template #label>
                        {{ $t('agency.labelAirUsername') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <!-- FIXME v-model -->
                      <!-- { label: 'Account 1', value: 'account1' },
                      { label: 'Account 2', value: 'account2' },
                      { label: 'Account 3', value: 'account3' }, -->
                      <v-select
                        id="airAccountUsername"
                        v-model="agencyToEdit.airAccount"
                        :options="[
                        ]"
                        :reduce="(val) => val.value"
                        :disabled="readonly"
                        clearable
                        input-id="airAccountUsername"
                        :placeholder="$t('agency.placeholderAirUsername')"
                      >
                        <template #option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Airline -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelAirline')"
                    rules=""
                  >
                    <b-form-group
                      label-for="airline"
                      :state="getValidationState(validationContext) === false
                        ? false
                        : null
                      "
                    >
                      <template #label>
                        {{ $t('agency.labelAirline') }}
                        <!-- <span
                          v-if="isEditActive"
                          class="text-danger"
                        >(*)</span> -->
                      </template>
                      <v-select
                        v-model="agencyToEdit.airline"
                        :options="hotAirlineOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        :disabled="readonly"
                        input-id="airline"
                        :placeholder="$t('agency.placeholderAirline')"
                      >
                        <template #option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $te(data.label) ? $t(data.label) : data.label }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <!-- ANCHOR - Mạng xã hội -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-50 mb-1"
            class="border my-1"
          >
            <template #header>
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6 class="text-body m-0">
                  Mạng xã hội
                </h6>

                <b-button
                  v-if="!readonly"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary.window
                  title="Chỉnh sửa"
                  size="sm"
                  variant="outline-info"
                  class="btn-icon mr-1 py-25 px-50 d-flex-center"
                  @click="editSocialNetwork(agencyToEdit)"
                >
                  <img src="@icons/edit.svg">
                  <span
                    v-if="!isMobileView"
                    class="font-medium-2 ml-25"
                    style="margin-top: 1px;"
                  >
                    Thay đổi mạng xã hội
                  </span>
                </b-button>
              </div>
            </template>

            <div v-if="agencyToEdit.socialAccounts && agencyToEdit.socialAccounts.length">
              <div
                v-for="(item, i) in agencyToEdit.socialAccounts"
                :key="i"
                class="mb-50 d-flex align-items-center"
              >
                <span
                  class="mr-50 font-weight-bolder"
                  :style="isMobileView ? `min-width: 60px` : `min-width: 120px`"
                >
                  {{ item.type }}
                </span>

                <b-link
                  class="url-ellipsis"
                  :href="item.url"
                  target="_blank"
                >
                  {{ item.url }}
                </b-link>
              </div>
            </div>

            <div v-else>
              Chưa thêm mạng xã hội
            </div>
          </b-card>

          <!-- ANCHOR - NOTE -->
          <b-card
            v-if="isRoleF1"
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-50 mb-1"
            class="border my-1"
          >
            <template #header>
              <div class="d-flex align-items-center justify-content-between w-100">
                <h6 class="text-body m-0">
                  {{ $t('reservation.note') }}
                </h6>
                <b-button
                  v-if="!readonly"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.v-primary.window
                  title="Chỉnh sửa"
                  size="sm"
                  variant="outline-info"
                  class="btn-icon mr-1 py-25 px-50 d-flex-center"
                  @click="editNote($event.target)"
                >
                  <img src="@icons/edit.svg">
                  <span
                    v-if="!isMobileView"
                    class="font-medium-2 ml-25"
                    style="margin-top: 1px;"
                  >
                    Sửa ghi chú
                  </span>
                </b-button>
              </div>
            </template>

            <div>
              {{ agencyToEdit.note || $t('reservation.noNote') }}
            </div>
          </b-card>

          <!-- SECTION audit log -->
          <AgenciesAuditLog :audit-logs="agencyToEdit.auditLogs" />
          <!-- !SECTION -->

          <!-- ANCHOR Action Buttons -->
          <div class="button_actions d-flex mt-1 justify-content-center">
            <!-- Back button -->
            <b-button
              variant="secondary"
              class="py-1 px-2 px-md-3 mr-1"
              @click="onCancel"
            >
              {{ $t('back') }}
            </b-button>
            <!-- Save button -->
            <b-button
              v-if="!readonly"
              variant="info"
              class="py-1 px-2 px-md-3"
              @click="handleSubmit(() => updatedAgencyHandle(true))"
            >
              {{ $t('save') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>

      <CreateTopupModal
        :sender="senderData"
        :recipient="recipientData"
        :is-empty-bank-accounts="isEmptyBankAccounts"
        :topup-records="agencyToEdit.topupRecords"
        :place="placeModal"
        @show="handleOpenTopupModal"
      />

      <ModalEditBalance
        :agencies-data="agenciesData"
        :current-agency-id="currentAgencyId"
        @refetchAgency="refetchAgency"
      />

      <AgenciesChangeBalanceModal
        :agencies-data="agencyToEdit"
        :limit-credit.sync="agencyToEdit.limitCredit"
        :warning-credit.sync="agencyToEdit.warningCredit"
        :is-role-f2="isRoleF2"
        :me-data="meData"
        :agencies-data-raw="agenciesData"
        @refetchAgency="refetchAgency"
      />

      <NoteModal
        v-if="agencyToEdit"
        :agency-to-edit="agencyToEdit"
        :note.sync="agencyToEdit.note"
        :agencies-data="agenciesData"
      />

      <ModalAddSocialNetwork
        v-if="agencyToEdit"
        :agency-to-edit="agencyToEdit"
        :social-accounts.sync="agencyToEdit.socialAccounts"
        :agencies-data="agenciesData"
      />
    </section>
  </IAmOverlay>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardTitle,
  BCardBody,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BFormDatepicker,
  VBTooltip,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref, computed, watch, onMounted,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import isEmpty from 'lodash/isEmpty'

import router from '@/router'
import store from '@/store'
import { hotAirlineOptions, statusAgencyOptions } from '@/constants/selectOptions'
import {
  MAMA_AGENCY_ID,
  AGENCY_STATUS_CAN_TOPUP,
  SENDER_TYPES,
} from '@/constants/topup'
import { TYPE_CAN_RESET_BALANCE_AGENCY } from '@/constants/agency'

import { getDifference } from '@core/utils/utils'
import formValidation from '@core/comp-functions/forms/form-validation'
import { formatCurrency, convertISODateTime, sizeFormatter } from '@core/utils/filter'
import { trimInput, upperCaseFormatter, trimAllInputAndUpper } from '@core/comp-functions/forms/formatter-input'
import { useInputImageFormData, uploadMultipleFile } from '@core/comp-functions/forms/form-utils'

import useAgenciesHandle, { shortenFileName } from '@agencies/useAgenciesHandle'

import useToast from '@useToast'
import {
  required, min, max, email, isUnique,
} from '@validations'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BForm,
    BCard,
    BCardBody,
    BCardTitle,
    BFormFile,
    BImg,
    BFormDatepicker,
    BLink,
    AgenciesAuditLog: () => import('@agencies/agencies-edit/AgenciesAuditLog.vue'),
    vSelect,

    ValidationProvider,
    ValidationObserver,

    NoteModal: () => import('./NoteModal.vue'),
    ModalAddSocialNetwork: () => import('./ModalAddSocialNetwork.vue'),
    AgenciesChangeBalanceModal: () => import('./AgenciesChangeBalanceModal.vue'),
    CreateTopupModal: () => import('@topup/topup-popup/CreateTopupModal.vue'),
    ModalEditBalance: () => import('./ModalEditBalance.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    agenciesData: {
      type: Object,
      required: true,
    },
    loadingProp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { toastError, toastSuccess } = useToast()
    const agencyFile = ref({
      idFrontImage: null,
      idBackImage: null,
      documentContract: null,
    })

    const agencyToEdit = ref(JSON.parse(JSON.stringify(props.agenciesData)))
    const resetAgencyToEdit = () => {
      agencyToEdit.value = JSON.parse(JSON.stringify(props.agenciesData))
    }

    onMounted(() => {
      store.dispatch('globalConfig/getAvailableDistributorSource')
    })
    watch(() => props.agenciesData, () => {
      resetAgencyToEdit()
    })
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAgencyToEdit)
    const readonly = ref(true)

    const isEditActive = computed(() => {
      const val = readonly.value === true ? '' : '(*)'
      return val
    })

    // for topup
    const currentAgencyId = Number(router.currentRoute.params.id)
    const agencyData = computed(() => store.getters['userStore/getAgencyData'])
    const allowTopup = computed(
      () => props.agenciesData?.status === AGENCY_STATUS_CAN_TOPUP
        && currentAgencyId
        && currentAgencyId !== MAMA_AGENCY_ID
        && agencyData.value
        && currentAgencyId !== agencyData.value?.id,
    )
    const allowEdit = computed(() => currentAgencyId !== agencyData.value?.id && currentAgencyId !== MAMA_AGENCY_ID)
    const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])

    const senderData = ref({})
    const recipientData = ref({})
    const isEmptyBankAccounts = ref(false)

    const setSenderData = () => {
      senderData.value = {
        id: agencyToEdit.value.id,
        agencyCode: agencyToEdit.value.agencyCode,
        agencyName: agencyToEdit.value.agencyName,
        type: SENDER_TYPES.AGENCY,
      }
    }

    const setRecipientData = () => {
      recipientData.value = {
        id: props.agenciesData.parentAgency.id,
        agencyName: props.agenciesData.parentAgency.agencyName,
        agencyCode: props.agenciesData.parentAgency.agencyCode,
        bankAccounts: props.agenciesData?.parentAgency?.bankAccs ?? props.agenciesData?.parentAgency?.bankAccounts ?? [],
      }

      if (!(props.agenciesData?.parentAgency?.bankAccs?.length ?? props.agenciesData?.parentAgency?.bankAccounts?.length)) {
        isEmptyBankAccounts.value = true
      }
    }

    const handleOpenTopupModal = () => {
      setSenderData()
      setRecipientData()
    }
    // END for topup

    const {
      updateAgencies,
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
      changeSocialNetwork,
      fileCanUpload,
      MAX_SIZE_TO_UPLOAD_FILE,
    } = useAgenciesHandle()

    const onCancel = () => {
      resetForm()
      router.go(-1)
    }

    function handleEdit() {
      readonly.value = false
    }

    function refetchAgency() {
      emit('refetchAgency')
    }

    async function updatedAgencyHandle() {
      // this.$refs.refFormObserver.validate()
      emit('update:loading-prop', true)
      try {
        const dataToUpdate = getDifference(agencyToEdit.value, props.agenciesData)
        if (dataToUpdate?.socialAccounts) {
          const payloadSocialAccounts = {
            socialAccounts: dataToUpdate.socialAccounts,
          }
          await changeSocialNetwork(props.agenciesData.id, payloadSocialAccounts)
          delete dataToUpdate.socialAccounts
        }

        if (!isEmpty(dataToUpdate)) {
          await updateAgencies(agencyToEdit.value, dataToUpdate, false)
        }
        toastSuccess({
          title: 'messagesList.success',
          content: 'messagesList.agency.edited',
        })
      } catch (error) {
        console.error({ error })
      } finally {
        refetchAgency()
        readonly.value = true
        emit('update:loading-prop', false)
      }
    }

    function handleCancelEdit() {
      resetForm()
      readonly.value = true
    }

    // Upload file
    const refInputFrontIDPhoto = ref(null)
    const refInputBackIDPhoto = ref(null)
    const refInputDocumentContractUrl = ref(null)

    const { inputImageFormData: inputImageIDCardFrontFormData } = useInputImageFormData(refInputFrontIDPhoto, data => {
      agencyToEdit.value.identityCardFrontImage = data.url
    })

    const { inputImageFormData: inputImageIDCardBackFormData } = useInputImageFormData(refInputBackIDPhoto, data => {
      agencyToEdit.value.identityCardBackImage = data.url
    })

    const loadingUpload = ref(false)
    async function handleUploadContractFiles() {
      loadingUpload.value = true
      try {
        const files = refInputDocumentContractUrl.value.files
        const uploadedFile = await uploadMultipleFile(files)
        agencyToEdit.value.documentContractUrl = [...agencyToEdit.value.documentContractUrl, ...uploadedFile.map(i => i.url)]
      } catch (error) {
        toastError(error.message)
      } finally {
        loadingUpload.value = false
      }
    }

    function openChangeBalanceModal(agencyToEdit) {
      if (agencyToEdit.id === meData.value?.agency.id) {
        toastError({
          title: 'messagesList.error',
          content: 'agency.notChangeYouBalance',
        })
      } else {
        this.$bvModal.show('modal-agencies-change-balance')
      }
    }

    function editNote(event) {
      this.$root.$emit('bv::show::modal', 'agency-change-note-modal', event)
    }

    async function editSocialNetwork(data) {
      await new Promise(resolve => {
        if (!data) return
        resolve()
      })

      this.$bvModal.show('modal-agency-add-social-network')
    }

    const isVisibleResetBalance = computed(() => isRoleF1.value && TYPE_CAN_RESET_BALANCE_AGENCY.includes(store.getters['userStore/getMeDataType']))

    const placeModal = ref('Agency')

    function handleShowModalCreateTopup() {
      this.$bvModal.show(`create-topup-modal${placeModal.value}`)
    }

    const isF3Retail = computed(() => props.agenciesData?.agencyCode.includes('-RTL'))

    function deleteDocumentContract(docIndex) {
      agencyToEdit.value.documentContractUrl.splice(docIndex, 1)
    }
    return {
      agencyToEdit,
      updatedAgencyHandle,
      onCancel,
      handleEdit,
      handleCancelEdit,
      refFormObserver,
      readonly,
      meData,
      // Options
      hotAirlineOptions,
      statusAgencyOptions,

      // Validation
      getValidationState,
      required,
      min,
      max,
      email,
      isUnique,

      isEditActive,

      // for topup
      allowTopup,
      allowEdit,
      senderData,
      recipientData,
      handleOpenTopupModal,

      // Upload file
      refInputFrontIDPhoto,
      refInputBackIDPhoto,
      refInputDocumentContractUrl,
      inputImageIDCardFrontFormData,
      inputImageIDCardBackFormData,

      // Filter Agency Manager
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,

      openChangeBalanceModal,

      trimInput,
      upperCaseFormatter,
      trimAllInputAndUpper,
      agencyFile,
      formatCurrency,

      editNote,
      editSocialNetwork,
      isRoleF1,
      isRoleF2,
      isRoleF3,
      isEmptyBankAccounts,
      refetchAgency,
      isVisibleResetBalance,
      currentAgencyId,

      handleShowModalCreateTopup,
      placeModal,
      convertISODateTime,
      isF3Retail,
      deleteDocumentContract,
      handleUploadContractFiles,
      shortenFileName,
      sizeFormatter,
      MAX_SIZE_TO_UPLOAD_FILE,
      fileCanUpload,
      loadingUpload,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.url-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

.button_actions {
  position: sticky;
  z-index: 22;
  bottom: 10px;
}

.hover-label {
  &:hover {
    transition: background-color 0.3s ease-in;
    opacity: 0.9;
  }
}

// input[type="file"] {
//   /* Input Style */
//   position: relative;
//   padding: 0.5rem;
//   color: var(--primary);
//   background-color: transparent;
//   border-radius: 0.3125rem;
//   cursor: pointer;
//   width: 100%;
//   border: 1px solid transparent;

//   &:hover {
//     border: 1px solid var(--primary);
//   }

//   /* Input Button */
//   &::file-selector-button {
//     padding: 0.625rem 0.9375rem 0.625rem 2.125rem;
//     color: #fff;
//     font-size: 0.9rem;
//     background-color: var(--primary);
//     border: none;
//     border-radius: 0.25rem;
//     cursor: pointer;
//     transition: background-color 0.3s ease-in;
//   }

//   /* Button Icon */
//   &::before {
//     position: absolute;
//     top: 50%;
//     left: 1.3625rem;
//     width: 0.9375rem;
//     height: 1.25rem;
//     transform: translateY(-50%);
//     pointer-events: none;
//     content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M448 416V352C448 334.326 433.672 320 416 320S384 334.326 384 352V416C384 433.674 369.672 448 352 448H96C78.328 448 64 433.674 64 416V352C64 334.326 49.672 320 32 320S0 334.326 0 352V416C0 469.02 42.98 512 96 512H352C405.02 512 448 469.02 448 416ZM246.625 342.625L374.625 214.625C387.133 202.117 387.117 181.867 374.625 169.375C362.125 156.875 341.875 156.875 329.375 169.375L256 242.75V32C256 14.312 241.688 0 224 0S192 14.312 192 32V242.75L118.625 169.375C106.125 156.875 85.875 156.875 73.375 169.375S60.875 202.125 73.375 214.625L201.375 342.625C213.875 355.125 234.125 355.125 246.625 342.625Z' fill='%23fff' /%3E%3C/svg%3E");
//   }
// }

.custom-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}
</style>
